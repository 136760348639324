import React, { useState } from "react"

import Layout from "../templates/Page"
import SwiperList from "../components/ContentBuilder/SwiperList"
import Header from "../components/ContentBuilder/Header"
import OtherAvailableOptions from "../components/ContentBuilder/SwiperList/OtherAvailableOptions"
import ProductThumbnails from "../components/ContentBuilder/SwiperList/ProductThumbnails"
import Subscribe from "../components/Footer/subscribe"
import { Breadcrumb, Container, Row, Col } from "react-bootstrap"

const SelectionsBoard = () => {
  const [isReadmore, setReadmore] = useState(true)
  const [isViewmore, setViewmore] = useState(true)

  return (
    <Layout hideHeader>
      <Header isLogin />
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              My Boards
            </li>
          </ol>
        </nav>
        
        <div className="account-avt-wrap">
          <div className="account-avt">
            <a href="/account/my-boards/" className="account-avt-inner">
              <span>TN</span>
            </a>
          </div>
          <p className="account-avt-name d-none d-lg-block">
            Thien Nguyen
          </p>
        </div>

        <div className="row no-gutters justify-content-center">
          <div className="col-12 col-lg-6 col-xl-5">
            <div className="my-account-header-wrap">
              <a className="my-account-header">
                <div className="my-account-header-title">My account</div>
              </a>
              <a className="my-account-header active" href="/account/my-boards/">
                <div className="my-account-header-title">My boards</div>
              </a>
            </div>
          </div>
        </div>

        <div className="board-page-title">
          <div className="row align-items-center">
            <div className="col-md">
              <h1 className="product-name-title text-uppercase">
                Selections board
              </h1>
            </div>
          </div>
        </div>

        <h2 className="product-name-title home-design-subtitle h3">My Rad Selections
          <a className="editboard ml-3" show="false" href="#">
            <img width="25" src="/img/account/edit.svg" alt="edit board" />
          </a>
        </h2>
        <div className="wrap-section row py-3">
          <div className="col-lg-3">
            <div className="sidebar-category">
              <div className="sub-title pb-2"><span>Room Selections</span></div>
              <ul className="link-nav">
                <li>Kitchen<a className="ml-2" href="#"><img width="25" src="/img/account/edit.svg" alt="" /></a></li>
                <li>Laundry<a className="ml-2" href="#"><img width="25" src="/img/account/edit.svg" alt="" /></a></li>
                <li>Ensuite<a className="ml-2" href="#"><img width="25" src="/img/account/edit.svg" alt="" /></a></li>
                <li>Powder room<a className="ml-2" href="#"><img width="25" src="/img/account/edit.svg" alt="" /></a></li>
                <li>External<a className="ml-2" href="#"><img width="25" src="/img/account/edit.svg" alt="" /></a></li>
                <li>Bedroom<a className="ml-2" href="#"><img width="25" src="/img/account/edit.svg" alt="" /></a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row board-wrap">
              <div className="col-6 col-lg-6 col-xl-4">
                <div className="addmore-wrap darker">
                  <a href="#" className="addmore-tile">
                    <svg className="addmore-icon" width="30px" height="30px">
                      <use xlinkHref="/img/icons.svg#icon-plus"></use>
                    </svg>
                    <div className="addmore-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                      >
                        <path
                          d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                          transform="translate(21.982 6.975)"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <h4>START YOUR<br/>BIRDY<br/>ADVENTURE</h4>
                    </div>
                    <div className="addmore-bottom"><p>MAKE YOUR HOME QUOTES</p></div>
                  </a>
                </div>
              </div>
              <div className="col-6 col-lg-6 col-xl-4">
                <div className="addmore-wrap">
                  <a href="#" className="addmore-tile">
                    <svg className="addmore-icon" width="30px" height="30px">
                      <use xlinkHref="/img/icons.svg#icon-plus"></use>
                    </svg>
                    <div className="addmore-center">
                      <h4>Secondary<br/>Flooring</h4>
                    </div>
                    <div className="addmore-bottom"><p>MAKE YOUR HOME QUOTES</p></div>
                  </a>
                </div>
              </div>
              <div className="col-6 col-lg-6 col-xl-4">
                <div className="addmore-wrap">
                  <a href="#" className="addmore-tile">
                    <svg className="addmore-icon" width="30px" height="30px">
                      <use xlinkHref="/img/icons.svg#icon-plus"></use>
                    </svg>
                    <div className="addmore-center">
                      <h4>Secondary<br/>Flooring</h4>
                    </div>
                    <div className="addmore-bottom"><p>MAKE YOUR HOME QUOTES</p></div>
                  </a>
                </div>
              </div>
              <div className="col-6 col-lg-6 col-xl-4">
                <div className="addmore-wrap">
                  <a href="#" className="addmore-tile">
                    <svg className="addmore-icon" width="30px" height="30px">
                      <use xlinkHref="/img/icons.svg#icon-plus"></use>
                    </svg>
                    <div className="addmore-center">
                      <h4>Secondary<br/>Flooring</h4>
                    </div>
                    <div className="addmore-bottom"><p>MAKE YOUR HOME QUOTES</p></div>
                  </a>
                </div>
              </div>
              <div className="col-6 col-lg-6 col-xl-4">
                <div className="addmore-wrap">
                  <a href="#" className="addmore-tile">
                    <svg className="addmore-icon" width="30px" height="30px">
                      <use xlinkHref="/img/icons.svg#icon-plus"></use>
                    </svg>
                    <div className="addmore-center">
                      <h4>Secondary<br/>Flooring</h4>
                    </div>
                    <div className="addmore-bottom"><p>MAKE YOUR HOME QUOTES</p></div>
                  </a>
                </div>
              </div>
              <div className="col-6 col-lg-6 col-xl-4">
                <div className="addmore-wrap">
                  <a href="#" className="addmore-tile">
                    <svg className="addmore-icon" width="30px" height="30px">
                      <use xlinkHref="/img/icons.svg#icon-plus"></use>
                    </svg>
                    <div className="addmore-center">
                      <h4>Secondary<br/>Flooring</h4>
                    </div>
                    <div className="addmore-bottom"><p>MAKE YOUR HOME QUOTES</p></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="product-name-title home-design-subtitle h3 pb-2">
          Optional Items.
          <span>Drag into the main area to include in your build</span>
        </h2>
        <div className="row board-wrap">
          <div className="col-6 col-lg-6 col-xl-3">
            <div className="addmore-wrap">
              <a href="#" className="addmore-tile">
                <span className="addmore-label">optional</span>
                <svg className="addmore-icon" width="30px" height="30px">
                  <use xlinkHref="/img/icons.svg#icon-plus"></use>
                </svg>
                <div className="addmore-center">
                  <h4>Wall<br/>Panelling</h4>
                </div>
                <div className="addmore-bottom"><p>MAKE YOUR COLLECTION</p></div>
              </a>
            </div>
          </div>
          <div className="col-6 col-lg-6 col-xl-3">
            <div className="addmore-wrap">
              <a href="#" className="addmore-tile">
                <span className="addmore-label">optional</span>
                <svg className="addmore-icon" width="30px" height="30px">
                  <use xlinkHref="/img/icons.svg#icon-plus"></use>
                </svg>
                <div className="addmore-center">
                  <h4>Wall<br/>Panelling</h4>
                </div>
                <div className="addmore-bottom"><p>MAKE YOUR COLLECTION</p></div>
              </a>
            </div>
          </div>
          <div className="col-6 col-lg-6 col-xl-3">
            <div className="addmore-wrap">
              <a href="#" className="addmore-tile">
                <span className="addmore-label">optional</span>
                <svg className="addmore-icon" width="30px" height="30px">
                  <use xlinkHref="/img/icons.svg#icon-plus"></use>
                </svg>
                <div className="addmore-center">
                  <h4>Wall<br/>Panelling</h4>
                </div>
                <div className="addmore-bottom"><p>MAKE YOUR COLLECTION</p></div>
              </a>
            </div>
          </div>
        </div>

        <h2 className="product-name-title home-design-subtitle h3 pb-2">
          Moodboards and personal items.
          <span>Add your own personal items to each room to complete the picture</span>
        </h2>
        <div className="row board-wrap">
          <div className="col-6 col-lg-6 col-xl-3">
          <div className="grid-item">
            <a className="grid-item-inner default-crop" href="/explore/cabinetry/bedroom-ledge/?vid=81">
              <img src="https://cms.uat.birdyhomes.com.au/assets/Uploads/Bedroom-Ledge-1-Display-Home-Luminaire-Willowdale-L-2.jpg" alt="Bedroom
                Ledge" />
              <div className="grid-caption">
                <h4>Bedroom
                  Ledge
                </h4>
                <div className="grid-bottom">
                  <p className="variant-name">Boathouse</p>
                </div>
              </div>
            </a>
          </div>
          </div>
          <div className="col-6 col-lg-6 col-xl-3">
            <div className="addmore-wrap">
              <a href="#" className="addmore-tile">
                <span className="addmore-label">optional</span>
                <svg className="addmore-icon" width="30px" height="30px">
                  <use xlinkHref="/img/icons.svg#icon-plus"></use>
                </svg>
                <div className="addmore-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 35 35"
                  >
                    <path
                      d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                      transform="translate(21.982 6.975)"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.5"
                    />
                  </svg>
                  <h4>Add<br/>personal<br/>or custom<br/>items</h4>
                </div>
                <div className="addmore-bottom"><p>MAKE YOUR COLLECTION</p></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SelectionsBoard
